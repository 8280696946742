/* eslint-disable no-undef */
import '../styles.css';
import '../search.css';
import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Logo from '../components/logo';
import SearchBox from '../components/searchbox';
import BoxMenuDrop from '../components/boxmenudrop';
import ProfileMenuDrop from '../components/profilemenudrop';
import { Content } from '../data/content';

function Home() {
  const history = useHistory();

  // These values will be shown in the search dropdown
  // The name property is the actual text and the value property is the link
  const options = [
    {
      name: 'everything about Giovanni',
      value: 'all',
    },
    {
      name: 'about',
      value: 'about',
    },
    { name: 'works', value: 'works' },
    { name: 'writing', value: 'writing' },
    { name: 'images', value: 'images' },
    { name: 'social', value: 'social' },
  ];

  // Website search
  const searchWebsite = () => {
    const path = document.querySelector('.search-input').value;
    // When search is triggered, add the value entered into the search bar to the url
    if (path) {
      history.push(path);
    }
  };

  useEffect(() => {
    const inputField = document.querySelector('.search-input');
    // Trigger search when the enter key is pressed
    inputField.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        searchWebsite();
      }
    });
    // eslint-disable-next-line
  }, []);

  // I'm Feeling Lucky search
  function feelingLucky() {
    const path = document.querySelector('.search-input').value;

    // Route to random page if search input is empty
    if (!path) {
      history.push(`/${options[Math.floor(Math.random() * options.length)].value}`);
      return;
    }

    /* Get all elements matching the search term */
    const item = Content.filter((item) => item.category === path);

    // Get the link of the first match
    // Redirect to first match, if it exists
    if (item[0]) {
      const url = item[0].link;
      window.location.href = url;
    } else if (path) {
      history.push(path);
    }
  }

  return (
    <div className="home main">
      <div className="top-menu">
        <span className="top-menu-item no-show-mobile">
          {' '}
          <a href="mailto:gjuliao32@gmail.com"> Email </a>
        </span>
        <span className="top-menu-item no-show-mobile">
          {' '}
          <a href="https://github.com/gjuliao"> GitHub </a>
        </span>
        <BoxMenuDrop />
        <ProfileMenuDrop />
      </div>
      <div className="flex-center">
        <div className="search-container">
          <div className="frontpage-logo">
            <Logo />
          </div>
          <SearchBox options={options} />
          <div className="search-btns">
            <input
              className="search-btn sw"
              type="button"
              value="Search Website"
              onClick={searchWebsite}
            />
            <input
              className="search-btn ifl"
              type="button"
              value="I'm Feeling Lucky"
              onClick={feelingLucky}
            />
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="country">
          Spain
        </div>
        <div className="footer-links">
          <div className="footer-links-section">
            <Link to="/about"> About </Link>
            <Link to="/projects"> Projects </Link>
            <Link to="/blog"> Blog</Link>
            <a href="mailto:gjuliao32@gmail.com"> Email </a>
          </div>
          <div className="footer-links-section">
            <a href="https://github.com/gjuliao"> GitHub </a>
            <a href="https://www.linkedin.com/in/giovannijuliao/"> LinkedIn </a>
            <a href="mailto:gjuliao32@gmail.com"> Email </a>
            <a href="https://medium.com/@gjuliao32"> Medium </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Home;
