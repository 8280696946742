const routes = [
  { path: '/', component: 'Home' },
  { path: '/all', component: 'AllResults' },
  { path: '/about', component: 'About' },
  { path: '/writing', component: 'Writing' },
  { path: '/works', component: 'Works' },
  { path: '/social', component: 'Social' },
  { path: '/projects', component: 'ProjectsPage' },
  { path: '/images', component: 'ImagesPage' },
  { path: '/blog', component: 'BlogPage' },
];

export default routes;
