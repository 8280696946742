import './styles.css';
import './search.css';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './routes'; // Import the routes

// Import page components
import Home from './pages/home';
import AllResults from './pages/allResults';
import About from './pages/about';
import Writing from './pages/writing';
import Works from './pages/works';
import Social from './pages/social';
import ProjectsPage from './pages/projectsPage';
import ImagesPage from './pages/imagesPage';
import BlogPage from './pages/blogPage';
import NotFound from './pages/notFound';

// Map component names to actual React components
const componentMap = {
  Home,
  AllResults,
  About,
  Writing,
  Works,
  Social,
  ProjectsPage,
  ImagesPage,
  BlogPage,
};

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.path}
              exact={route.path === '/'}
              path={route.path}
              component={componentMap[route.component]}
            />
          ))}
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
